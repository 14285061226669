import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/**
 * User store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
    ...mapActions({
      loadNotifications:"notification/LOAD_NOTIFICATIONS"
    }),
    ...mapMutations({
      addNotification:"notification/ADD_NOTIFICATION",
      resetStateNotification:"notification/RESET_STATE"
    }),
  },

    computed: {
    ...mapState("notifications", []),
    }
 */

const getDefaultState = () => {
  return {
    notifications: [],
    lastNotification: null,
    limitDisplayNotifications: 20
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {};

// mutations
const mutations = {
  ADD_NOTIFICATION(state, notification) {
    state.lastNotification = notification;
    state.notifications.unshift(notification);
    if (state.notifications.length > state.limitDisplayNotifications) {
      state.notifications = state.notifications.slice(0, state.limitDisplayNotifications);
    }
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  UPDATE_NOTIFICATION(state, notification) {
    const index = state.notifications.findIndex(notif => notif._id === notification._id);
    if (index >= 0) {
      Object.assign(state.notifications[index], notification);
    }
  },
  CLEAR_NOTIFICATIONS(state) {
    state.notifications = [];
  },
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  LOAD_NOTIFICATIONS(context) {
    return this.$axios.get(`/api/v1/notification/`, {
      params: {
        limit: context.state.limitDisplayNotifications
      }
    }).then(r => r.data)
      .then(
        notifications => {
          context.commit('SET_NOTIFICATIONS', notifications);
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          context.commit('CLEAR_NOTIFICATIONS');
          return false;
        }
      );
  },
  SET_READ_NOTIFICATION(context, { notificationId }) {
    return this.$axios.put('/api/v1/notification/' + notificationId, {
      read: true
    }).then(r => r.data)
      .then(
        notification => {
          context.commit('UPDATE_NOTIFICATION', notification);
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  CLEAR_NOTIFICATIONS(context) {
    context.commit('CLEAR_NOTIFICATIONS')
  }
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
