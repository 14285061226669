import Vue from 'vue';
import Vuex from 'vuex';
import Utils from '~/common/utils/store';
import packageJson from '~/package.json';

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    apiBuild: 0,
    adminDashboard: {},
    settings: {
      adminMode: false,
      maintenanceMode: false,
      forceMaintenanceAccess: false,
      publicMessage: null,
      publicMessageClosable: false,
    },
    existingLanguages: ['en', 'fr'],
    appGUI: {
      debug: {
        svgMode: false
      },
      flatplan: {
        contentBaseWidth: 210,
        state: 'opened', // values : opened / extended / closed
      },
      pageEditor: {
        marginTop: 60,
        zoomValues: [10, 25, 50, 75, 100, 150, 200, 250, 300],
        centerAnimationDuration: 250
      },
      adminFilters: {
        templates: [],
        formats: [],
        clientUsers: [],
        adminUsers: [],
        offers: [],
        orders: [],
        magazines: [],
        dashboard: [],
        workspaces: [],
        organizations: [],
        authStrategies: [],
        groupOffers: [],
      }
    }
  };
};

// initial state
const state = getDefaultState;


// getters are functions
const getters = {

  appGUI: state => state.appGUI,

  appBuild: state => {
    const version = packageJson.version || 0
    return version;
  },

  apiBuild: state => state.apiBuild,

  publicMessage: state => {
    return state.settings.publicMessage;
  },
  publicMessageClosable: state => {
    return state.settings.publicMessageClosable;
  },
  maintenanceMode: state => {
    return state.settings.maintenanceMode;
  },
  forceMaintenanceAccess: state => {
    return state.settings.forceMaintenanceAccess;
  },
  existingLanguages: state => {
    return state.existingLanguages;
  },

  adminMode: state => {
    return state.settings.adminMode;
  },

  getAdminFiltersQuery: state => type => {
    return state.appGUI.adminFilters[type].reduce((list, filter) => {
      list = Object.assign({}, list, filter.query);
      return list;
    }, {});
  }
};

const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.user
   */
  UPDATE_SETTINGS: (state, {
    key,
    value
  }) => {
    Utils.setProperty(key, state.settings, value);
  },

  UPDATE_INTERFACE: (state, {
    key,
    value
  }) => {
    Utils.setProperty(key, state.appGUI, value);
  },

  UPDATE_ROOT_FIELD: (state, {
    key,
    value
  }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {

  GET_API_BUILD: function () {
    return this.$axios
      .get('/build')
      .then(r => r.data)
      .then(
        json => {
          this.commit('app/UPDATE_ROOT_FIELD', {
            key: 'apiBuild',
            value: json.v
          });
          return true;
        },
        err => {
          return false;
        }
      );
  },

  /**
  * fetch number of users
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} query - see more https://github.com/loris/api-query-params
  * @return Promise
  */
  LOAD_ADMIN_DASHBOARD: async function (context, payload) {

    return this.$axios
      .get('/api/v1/admin/dashboard?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        result => {
          this.commit('app/UPDATE_ROOT_FIELD', {
            key: 'adminDashboard',
            value: result
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'ADMIN_DASHBOARD are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
