import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';
/**
 * return default article object
 */
function initialState() {
  return {
    _id: null,
    name: 'New Article',
    magazine: null,
    textblocks: [],
    pages: [],
  };
}

function getBlockTypeOrder(type) {
  const weights = {
    'Header': 1,
    'Body': 2,
    'Box': 3,
    'Legend': 4,
    'Footer': 5
  }
  return weights[type]
}

const getDefaultState = () => {
  return {
    articles: [],
    article: initialState(),
    textblockMap: {},
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  /**
   * return collection articles
   */

  getArticles: state => state.articles,
  getTextblock: state => id => state.textblockMap[id],
  getTextblockList: state => {
    let output = [];
    for (const key in state.textblockMap) {
      output.push(state.textblockMap[key])
    }

    // sort by type
    output.sort((a, b) => {
      return getBlockTypeOrder(a.type) - getBlockTypeOrder(b.type)
    })

    return output;
  },

  getTextblockByTarget: state => targetId => {
    for (const key in state.textblockMap) {
      if (state.textblockMap[key].target === targetId) {
        return state.textblockMap[key];
      }
    }
    return null
  },

  /**
   * return defaut state of article object
   */
  getArticleOfPage: state => pageId => {
    let found = state.articles.find(article => article.pages.indexOf(pageId) !== -1)
    if (!found || found === undefined) {
      return false
    }
    return found
  }

};
// mutations
const mutations = {

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} articles
   *
   * set collections data articles
   */
  SET_ARTICLES: (state, articles) => {
    state.articles = articles;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add article object in collection from payload.article parameter
   */
  ADD_ARTICLE: (state, payload) => {
    state.articles.push(payload.article);
  },

  /**
   * Set temporary new article with pageId and MagazineId
   */
  SET_NEW_ARTICLE: (state, payload) => {
    let newArticle = initialState()
    newArticle.magazine = payload.magazine;
    newArticle.pages.push(payload.page);
    state.article = Object.assign({}, state.article, newArticle);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update article object in collection from payload.article parameter
   */
  UPDATE_ARTICLE: (state, payload) => {
    const _index = state.articles.findIndex(article => article._id === payload.article._id);
    if (_index >= 0) {
      Object.assign(state.articles[_index], payload.article);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} articleId
   *
   * remove article object from collection with articleId
   */
  REMOVE_ARTICLE: (state, articleId) => {
    const _index = state.articles.findIndex(article => article._id === articleId);
    if (_index >= 0) {
      state.articles.splice(_index, 1);
    }
  },

  SET_ARTICLE_OF_PAGE: (state, pageId) => {
    if (pageId === null) {
      state.article = Object.assign({}, initialState());
    } else {
      let found = state.articles.find(article => article.pages.indexOf(pageId) !== -1)
      state.article = Object.assign({}, state.article, found);
    }
  },

  SET_ARTICLE: (state, id) => {
    if (id === null) {
      state.article = Object.assign({}, initialState());
    } else {
      state.article = Object.assign({}, state.article, state.articles.find(article => article._id === id));
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.article
   */
  UPDATE_FIELD: (state, {
    key,
    value
  }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.article
   */
  UPDATE_ARTICLE_ARRAY: (state, payload) => {
    const _index = payload.cb ? state.article[payload.key].findIndex(payload.cb) : state.article[payload.key].indexOf(payload.value);
    if (_index >= 0) {
      if (payload.delete === true) {
        state.article[payload.key].splice(_index, 1); // delete
      } else {
        state.article[payload.key].splice(_index, 1, payload.value); // update
      }
    } else {
      state.article[payload.key].push(payload.value); // add
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.article
   */
  RESET_ARTICLE: state => {
    state.article = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },

  /**
   * ==================
   * TEXBLOCK MUTATIONS
   * ==================
   */

  SET_TEXT: (state, payload) => {
    if (state.textblockMap[payload.id]) {
      const textblock = state.textblockMap[payload.id];
      textblock.html = payload.html;
    }
  },

  SET_TEXTBLOCK_TYPE: (state, payload) => {
    state.textblockMap[payload.id].type = payload.type;
  },

  /**
   * Update item in textblock list
   */
  UPDATE_TEXBLOCK_MAP: (state, textblock) => {
    Vue.set(state.textblockMap, textblock._id, textblock);
  },

  REMOVE_FROM_TEXBLOCK_MAP: (state, textblockId) => {
    Vue.delete(state.textblockMap, textblockId);
  },

  RESET_TEXBLOCKS: (state) => {
    Vue.set(state, 'textblockMap', {});
  },

};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of articles
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} payload
   * @return Promise
   */
  LOAD_ARTICLES: async function (context, payload) {
    // don't reload articles list if already in state
    const {
      magazine,
    } = payload;
    if (context.state.articles.length > 0 && context.state.articles[0].magazine == magazine) {
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/article?magazine=' + magazine)
      .then(r => r.data)
      .then(
        articles => {
          this.commit('article/SET_ARTICLES', articles);
          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'ARTICLES are loaded'
          });

          return true;
        },
        err => {
          if (err.response.status === 404) {
            // if article is not found (not yet created) mute error
            this.commit('article/RESET_ARTICLE');
            this.commit('article/UPDATE_FIELD', {
              key: 'article.magazine',
              value: magazine
            });
            if (page) {
              this.commit('article/UPDATE_ARTICLE_ARRAY', {
                key: 'page',
                value: page
              });
            }
            return true
          } else {
            this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
            return false;
          }
        }
      );
  },

  /**
   * upsert current article object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_ARTICLE: function (context, payload) {
    let method = context.state.article._id === null ? 'post' : 'put';
    let updateId = context.state.article._id === null ? '' : context.state.article._id;

    return this.$axios[method]('/api/v1/article/' + updateId, context.state.article)
      .then(r => r.data)
      .then(
        article => {
          // update article list if creation
          if (updateId.length === 0) {
            this.commit('article/ADD_ARTICLE', {
              article
            });
          } else {
            this.commit('article/UPDATE_ARTICLE', {
              article
            });
          }

          // set loaded article as current article
          this.commit('article/SET_ARTICLE', article._id);

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'article is saved'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * delete current article object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  DELETE_ARTICLE: function (context, payload) {
    let id = payload.id || context.state.article._id;

    return this.$axios
      .delete('/api/v1/article/' + id)
      .then(r => r.data)
      .then(
        article => {
          this.commit('article/REMOVE_ARTICLE', id);
          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'article has been deleted'
          });

          this.commit('article/RESET_ARTICLE');
          this.commit('article/RESET_TEXBLOCKS');
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * ==================
   * TEXTBLOCK ACTIONS
   * ==================
   */

  SAVE_TEXTBLOCK: async function (context, payload) {
    let data = {}

    if (payload.keys) {
      // update only needed fields
      for (let _key of payload.keys) {
        data[_key] = context.state.textblockMap[payload.id][_key];
      }
    } else {
      // update all fields
      data = context.state.textblockMap[payload.id];
    }

    return this.$axios.put('/api/v1/textblock/' + payload.id, data)
      .then(r => r.data)
      .then(
        textblock => {

          // update all textblocks list
          this.commit('article/UPDATE_TEXBLOCK_MAP', textblock);

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'text is saved'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  UNDO_TEXTBLOCK: async function (context, payload) {
    let data = {}

    return this.$axios.put('/api/v1/textblock/undo/' + payload.id)
      .then(r => r.data)
      .then(
        textblock => {
          // update all textblocks list
          this.commit('article/UPDATE_TEXBLOCK_MAP', textblock);

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'text is saved'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  REDO_TEXTBLOCK: async function (context, payload) {
    return this.$axios.put('/api/v1/textblock/redo/' + payload.id)
      .then(r => r.data)
      .then(
        textblock => {
          // update all textblocks list
          this.commit('article/UPDATE_TEXBLOCK_MAP', textblock);

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'text is saved'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  CREATE_BATCH_TEXBLOCKS: function (context, payload) {
    let {
      textboxes,
      pageId
    } = payload;
    let articleId = context.state.article._id
    //normalize object
    let voList = textboxes.map(tb => {
      return {
        target: tb.id,
        type: tb.blockType,
        page: pageId,
        article: articleId
      }
    })

    let data = {
      article: articleId,
      list: voList
    }

    return this.$axios.post('/api/v1/textblock/batch/', data)
      .then(r => r.data)
      .then(
        textblocks => {

          textblocks.forEach(textblock => {
            this.commit('article/UPDATE_TEXBLOCK_MAP', textblock);
            this.commit('article/UPDATE_ARTICLE_ARRAY', {
              key: 'textblocks',
              value: textblock._id
            });
          })

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'article is saved'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * delete specific textblock from article
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  DELETE_TEXTBLOCK: function (context, payload) {
    return this.$axios
      .delete('/api/v1/textblock/' + payload)
      .then(r => r.data)
      .then(
        article => {
          this.commit('article/REMOVE_FROM_TEXBLOCK_MAP', payload);
          this.commit('article/UPDATE_ARTICLE_ARRAY', {
            key: 'textblocks',
            value: payload,
            delete: true
          });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'textblock has been deleted'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  LOAD_ARTICLE_TEXBLOCKS: async function (context, payload) {
    return this.$axios.get('/api/v1/textblock/?article=' + context.state.article._id)
      .then(r => r.data)
      .then(
        ({ textblocks }) => {

          textblocks.forEach(textblock => {
            this.commit('article/UPDATE_TEXBLOCK_MAP', textblock);
          })

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'textblocks loaded'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  }
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
