<template>
  <div
    ref="pageEditorContent"
    class="pageEditorContent"
    v-loading="!backgroundProcess && loading"
  >
    <canvas v-if="canvasDomId" :id="canvasDomId"></canvas>
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import FabricUtils from "~/common/utils/fabricUtils";
import ImageUtils from "~/common/utils/imageUtils";
import MiscUtils from "~/common/utils/misc";

export default {
  name: "PageEditor",

  props: [
    "pageId",
    "paletteId",
    "editDisabled",
    "useSpaceForPanning",
    "lowDefinitionImage",
    "backgroundProcess",
  ],

  computed: {
    ...mapState("page", ["page"]),
    ...mapState("magazine", ["magazine", "template"]),
    ...mapState("palette", ["palette"]),
    ...mapState("theme", ["theme"]),
    ...mapState("fabric", ["activeObject"]),
    ...mapState("library", ["publicTemplateLibrary"]),
    ...mapGetters("theme", ["getFormatedStylesForFabric"]),

    ...mapState("collaboration", ["userCollaboration"]),

    ...mapGetters("collaboration", [
      "collaborationIsActive",
      "activeCollaborationUserByPages",
    ]),

    ...mapGetters("app", ["adminMode", "appGUI"]),
    ...mapGetters("api", ["getUID"]),

    canEditPage() {
      if (this.editDisabled || this.magazine.status === "Published") {
        return false;
      } else {
        return this.isPageOwner;
      }
    },
    isPageOwner() {
      if (this.collaborationIsActive) {
        const collabPage = this.activeCollaborationUserByPages[this.page._id];
        if (collabPage) {
          return collabPage.user._id === this.userCollaboration._id;
        } else {
          return this.userCollaboration.role === "chief";
        }
      } else {
        return this.magazine.owner === this.getUID;
      }
    },
  },
  data() {
    return {
      loading: false,
      reloadIfCanEditChange: false,
      pageToLoadAfterLoadingFinish: null,
      forceRefreshCacheFont: false,
      currentTextSize: null,
      hiddenObjects: [],
      currentPageId: null,
      canvasDomId: null,
    };
  },
  mounted() {
    this.canvasDomId = "background_" + MiscUtils.uniqidplus();
    if (this.pageId) {
      this.loadEditorForPage(this.pageId);
    }
  },
  beforeDestroy() {
    this.unloadEditor();
    this.canvasDomId = null;
  },
  methods: {
    ...mapActions({
      loadTemplate: "magazine/LOAD_TEMPLATE",
      loadPage: "page/LOAD_PAGE",
      loadTheme: "theme/LOAD_THEME",
      loadThemePalettes: "palette/LOAD_THEME_PALETTES",
      apiSendError: "api/API_SEND_ERROR",
    }),
    ...mapMutations({
      updateFabricStateField: "fabric/UPDATE_ROOT_FIELD",
      clearActiveObject: "fabric/CLEAR_ACTIVE_OBJECT",
      setActiveObject: "fabric/SET_ACTIVE_OBJECT",
      selectPalette: "palette/SET_CURRENT_PALETTE",
    }),
    loadEditorForPage: async function (pageId_, paletteId_) {
      if (this.loading) {
        this.pageToLoadAfterLoadingFinish = pageId_;
        return;
      }
      console.log(
        "DEBUG loadEditorForPage received , unload editor",
        pageId_,
        paletteId_
      );

      this.unloadEditor();
      this.loading = true;
      this.reloadIfCanEditChange = false;

      this.currentPageId = pageId_;
      await this.loadPage(pageId_);

      // force reload template, theme and theme palette if not in store (needed to generate fabric page and svg page)
      await this.loadTemplate(this.magazine.template);
      await this.loadTheme(this.magazine.theme);
      await this.loadThemePalettes(this.magazine.theme);

      console.log("DEBUG loadEditorForPage", { ...this.page });

      if (paletteId_) {
        this.selectPalette(paletteId_);
      }
      // select page palette if any (public mode)
      else if (this.page.palette) {
        this.selectPalette(this.page.palette);
      }
      // select first palette in theme
      else if (
        this.theme &&
        this.theme.paletteList &&
        this.theme.paletteList.length > 0
      ) {
        this.selectPalette(this.theme.paletteList[0]._id);
      }

      const newLoadedFonts = await this.$fontManager.loadFontList(
        this.theme.fonts
      );
      this.forceRefreshCacheFont = newLoadedFonts && newLoadedFonts.length > 0;

      this.initCanvas();
      this.initPage();
    },
    unloadEditor() {
      this.currentPageId = null;
      this.$fabric.disposeCanvas();
      this.clearActiveObject();
      this.removeCanvasListeners();
      this.loading = false;
    },
    initCanvas() {
      console.log("init canvas");
      this.$fabric.createCanvas(
        this.canvasDomId,
        this.$refs.pageEditorContent.clientWidth,
        this.$refs.pageEditorContent.clientHeight,
        {
          adminMode: this.adminMode,
          useSpaceForPanning: this.useSpaceForPanning,
        }
      );
    },
    async initPage() {
      this.clearActiveObject();

      if (!this.$fabric.canvas) {
        return;
      }

      this.$fabric.canvas.clear();

      if (
        !this.magazine ||
        !this.magazine.format ||
        !this.magazine._id ||
        !this.page ||
        !this.page._id
      ) {
        console.error(
          "invalid magazine data for init page",
          JSON.parse(JSON.stringify(this.magazine))
        );
        console.error(
          "invalid page data for init page",
          JSON.parse(JSON.stringify(this.page))
        );
        return;
      }

      // init page
      let options = {};
      options.pageId = this.page._id;
      options.pageRect = {
        top: 10,
        left: 10,
        width: this.magazine.format.canvasWidth,
        height: this.magazine.format.canvasHeight,
      };
      options.format = this.magazine.format;
      options.double = this.page.double;
      options.hideOutContent = !this.adminMode;
      options.hideMargin = !this.adminMode;

      options.specificMagazineData = FabricUtils.generateSpecificMagazineData({
        template: this.template,
        magazine: this.magazine,
        page: this.page,
        palette: this.palette,
        theme: this.theme,
      });

      this.$fabric.createPage(options);
      this.centerFabricPageOnScreen(false);

      // set styles list
      this.$fabric.setTextStylesList(this.getFormatedStylesForFabric);

      if (this.page.pageData) {
        let formatedData;
        try {
          formatedData = this.formatPageData();
        } catch (error) {
          console.error(
            "error on PageEditor.vue - initPage - formatPageData",
            error
          );
          this.apiSendError({
            from: "PageEditor.vue - initPage - formatPageData",
            error,
          });
        }
        try {
          await this.$fabric.page.import(formatedData);
        } catch (error) {
          console.error("error on PageEditor.vue - initPage - import", error);
          this.apiSendError({
            from: "PageEditor.vue - initPage - import",
            error,
            formatedData,
          });
        }
      }

      this.initPageComplete();
    },
    centerFabricPageOnScreen(applyMargin = true) {
      if (this.$fabric.canvas) {
        const zoom = this.$fabric.canvas.centerOnScreen(
          applyMargin
            ? {
                top: this.appGUI.pageEditor.marginTop,
              }
            : {}
        );
        if (!this.backgroundProcess) {
          this.updateFabricStateField({
            key: "canvas.zoom",
            value: zoom * 100,
          });
        }
      }
    },
    initPageComplete() {
      if (this.pageToLoadAfterLoadingFinish) {
        // load next page if ask during loading
        this.loading = false;
        this.loadEditorForPage(this.pageToLoadAfterLoadingFinish);
        this.pageToLoadAfterLoadingFinish = null;
        return;
      }

      // force refresh if new fonts loaded (bugfix measure text)
      // if (this.forceRefreshCacheFont) {
      setTimeout(() => {
        this.forceRefreshCacheFont = false;
        this.$fabric.page.clearFontCache();
        this.$nextTick(() => {
          this.initPageFinished();
        });
      }, 250);
      // } else {
      //   this.initPageFinished();
      // }
    },
    initPageFinished() {
      this.centerFabricPageOnScreen();
      if (!this.backgroundProcess) {
        this.initCanvasListeners();
      }
      this.$emit("ready", { hiddenObjects: this.hiddenObjects });
      this.loading = false;
    },
    formatPageData() {
      const formatedPageData = FabricUtils.formatPageDataBeforeImport({
        magazine: this.magazine,
        page: this.page,
        palette: this.palette,
        lowDefinitionImage: this.lowDefinitionImage,
      });

      this.hiddenObjects = [];

      const extractObjects = formatedPageData.objects.reduce((list, object) => {
        list.push(object);
        if (object.type === "MzGroup" && object.objects) {
          list.push(...object.objects);
        }
        return list;
      }, []);

      for (const object of extractObjects) {
        // set readOnly to object if user can't edit page
        object.readOnly =
          !this.canEditPage ||
          (!this.adminMode && object.clientSideLocked) ||
          (this.adminMode && object.adminSideLocked);

        // lock object for client editor
        object.locked = !this.adminMode;

        // save hidden objects
        if (object.type === "MzTextbox" && object.opacity === 0) {
          this.hiddenObjects.push(object.id);
        }

        //define if object shapeGroup has variant for client editor mode
        if (
          !this.adminMode &&
          object.type === "MzShapeGroup" &&
          this.publicTemplateLibrary.shapes.length > 0
        ) {
          const key = ImageUtils.getImageBaseKey(object.sourcePath);
          const shape = this.publicTemplateLibrary.shapes.find(
            (shape) => shape.key === key
          );
          object.variantGroup =
            shape && shape.variantGroup ? shape.variantGroup : null;
        }
      }

      this.reloadIfCanEditChange = true;

      return formatedPageData;
    },
    updateImageSourceFromCropped(activeObject, source, cropped) {
      // add try catch to debug infinte loading on change image source
      let croppedImageSrc, _w, _h, _scale;
      try {
        croppedImageSrc = ImageUtils.getImageBaseSrc(cropped);
        _w = cropped.rect.width;
        _h = cropped.rect.height;
        _scale = cropped.scale;

        if (this.lowDefinitionImage) {
          const neededScale = 1;

          activeObject.updateOriginalDefinitionData({
            src: croppedImageSrc,
            scaleX: cropped.scale,
            scaleY: cropped.scale,
            width: cropped.rect.width,
            height: cropped.rect.height,
          });

          const resizeData = ImageUtils.getFabricImageResizedData(
            {
              src: croppedImageSrc,
              scaleX: cropped.scale,
              scaleY: cropped.scale,
              width: cropped.rect.width,
              height: cropped.rect.height,
            },
            neededScale
          );

          _w = resizeData.width;
          _h = resizeData.height;
          _scale = resizeData.scaleX;
          croppedImageSrc = resizeData.src;
        }
      } catch (error) {
        return Promise.reject(error);
      }

      return new Promise((resolve, reject) => {
        try {
          activeObject.updateImageBeforeChangeSrc(_scale, _scale);

          activeObject.setSrc(
            croppedImageSrc,
            (loadedImg) => {
              if (!loadedImg || !loadedImg._element) {
                // Fabric Image._element is the loaded image element, set as null by fabric if loaded error
                reject({
                  error: "empty image loaded",
                  src: croppedImageSrc,
                  cropped,
                });
              } else {
                loadedImg.set("dirty", true);
                // loadedImg.set("scaleX", 1);
                // loadedImg.set("scaleY", 1);
                // loadedImg.set("cropX", 0);
                // loadedImg.set("cropY", 0);
                loadedImg.set("width", _w);
                loadedImg.set("height", _h);
                this.$fabric.refresh(loadedImg);

                resolve();
              }
            },
            { crossOrigin: "anonymous" }
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    initCanvasListeners() {
      if (this.$fabric && this.$fabric.canvas) {
        this.$fabric.canvas.on("selection:cleared", this.onSelectionCleared);
        this.$fabric.canvas.on("selection:created", this.onSelectionUpdate);
        this.$fabric.canvas.on("selection:updated", this.onSelectionUpdate);
        this.$fabric.canvas.on("canvas:zoom", this.onCanvasZoom);
        this.$fabric.canvas.on("object:rotating", this.onObjectTranform);
        this.$fabric.canvas.on("object:scaling", this.onObjectTranform);
        this.$fabric.canvas.on("object:wheelScaling", this.onObjectTranform);
        this.$fabric.canvas.on("object:modified", this.onObjectTranform);
        this.$fabric.canvas.on("object:moving", this.onObjectTranform);
        this.$fabric.canvas.on("object:cropping", this.onObjectTranform);
        this.$fabric.canvas.on("object:removed", this.onObjectRemoved);
      }
    },
    removeCanvasListeners() {
      if (this.$fabric && this.$fabric.canvas) {
        this.$fabric.canvas.off("selection:cleared", this.onSelectionCleared);
        this.$fabric.canvas.off("selection:created", this.onSelectionUpdate);
        this.$fabric.canvas.off("selection:updated", this.onSelectionUpdate);
        this.$fabric.canvas.off("canvas:zoom", this.onCanvasZoom);
        this.$fabric.canvas.off("object:rotating", this.onObjectTranform);
        this.$fabric.canvas.off("object:scaling", this.onObjectTranform);
        this.$fabric.canvas.off("object:wheelScaling", this.onObjectTranform);
        this.$fabric.canvas.off("object:modified", this.onObjectTranform);
        this.$fabric.canvas.off("object:moving", this.onObjectTranform);
        this.$fabric.canvas.off("object:cropping", this.onObjectTranform);
        this.$fabric.canvas.off("object:removed", this.onObjectRemoved);
      }
    },
    onSelectionCleared(e) {
      this.clearActiveObject();
      this.$bus.$emit("selectionCleared");
    },
    onSelectionUpdate(e) {
      this.setActiveObject({ value: this.$fabric.activeObject });

      this.$bus.$emit("activeObjectChanged");

      if (this.activeObject.type === "MzTextbox") {
        this.$bus.$emit("textBoxSelectionChanged");
        this.initTextListeners();
        this.currentTextSize = {
          width: this.activeObject.width,
          height: this.activeObject.height,
        };
      } else {
        this.removeTextListeners();
        this.currentTextSize = null;
      }
    },
    onCanvasZoom(e) {
      this.updateFabricStateField({ key: "canvas.zoom", value: e.zoom * 100 });
    },
    onObjectTranform(e) {
      let obj = this.$fabric.activeObject;
      if (!obj || !this.activeObject) {
        return;
      }

      //default update width & height (occur when editing text)
      let updateKeys = ["width", "height"];
      if (e.transform) {
        //other transform updates
        switch (e.transform.action) {
          case "drag":
            updateKeys = ["top", "left"];
            break;
          case "crop":
            updateKeys = ["cropX", "cropY"];
            break;
          case "scale":
            updateKeys = ["scaleX", "scaleY", "width", "height", "angle"];
          case "rotate":
            updateKeys = ["angle"];
          default:
            break;
        }
      }

      updateKeys.forEach((key) => {
        if (this.activeObject[key] != obj[key]) {
          this.updateFabricStateField({
            key: "activeObject." + key,
            value: obj[key],
          });
        }
      });
    },
    onObjectRemoved(e) {
      this.clearActiveObject();
    },
    initTextListeners() {
      this.removeTextListeners();
      this.$fabric.canvas.on("text:error", this.onTextStyleError);
    },
    removeTextListeners() {
      this.$fabric.canvas.off("text:error", this.onTextStyleError);
    },
    onTextStyleError(e) {
      let _msg;
      if (e.errors.text) {
        _msg = this.$t("public.edit_magazine.edit_textbox_error.text");
      } else if (e.errors.styles) {
        _msg = this.$t("public.edit_magazine.edit_textbox_error.style");
      }

      if (_msg) {
        this.$message({
          type: "error",
          message: _msg,
        });
      }
      this.$fabric.refresh();
    },
  },
  watch: {
    canEditPage(value) {
      if (this.reloadIfCanEditChange) {
        // reload page to change edit mode
        this.reloadIfCanEditChange = false;
        this.initPage();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pageEditorContent {
  width: 100%;
  height: 100%;

  & /deep/.canvas-container {
    position: absolute !important;
    left: 50% !important;

    & canvas {
      left: -50% !important;
    }
  }
}
</style>