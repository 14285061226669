<template>
  <el-container id="app">
    <el-main>
      <nuxt />
    </el-main>

    <div class="change-locale-button">
      <MzSwitchLang />
    </div>

    <div class="version">api: {{ apiBuild }} | app: {{ appBuild }}</div>

    <MaintenanceAlert />
  </el-container>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import MaintenanceAlert from "~/components/MaintenanceAlert";
import MzSwitchLang from "~/components/MzSwitchLang";

export default {
  head() {
    return {
      title: this.$t("layout.html.title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("layout.html.description"),
        },
        {
          hid: "ogtitle",
          property: "og:title",
          content: this.$t("layout.html.title"),
        },
        {
          hid: "ogsitename",
          property: "og:site_name",
          content: this.$t("layout.html.title"),
        },
        {
          hid: "ogdescription",
          property: "og:description",
          content: this.$t("layout.html.description"),
        },
        {
          hid: "itempropname",
          itemprop: "name",
          content: this.$t("layout.html.title"),
        },
      ],
    };
  },

  components: {
    MaintenanceAlert,
    MzSwitchLang,
  },
  computed: {
    ...mapState("app", ["apiBuild"]),
    ...mapGetters("app", ["appBuild"]),
  },
  created() {
    this.getBuildVersion();
  },
  methods: {
    ...mapActions({
      getBuildVersion: "app/GET_API_BUILD",
    }),
  },
};
</script>

<style lang="scss" scoped>
.change-locale-button {
  position: absolute;
  right: 25px;
  top: 25px;
}
.version {
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #aaa;
  pointer-events: none;
}
</style>
