<template>
  <div id="app">
    <el-main>
      <nuxt />
    </el-main>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  head() {
    return {
      title: this.$t("layout.html.title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("layout.html.description"),
        },
        {
          hid: "ogtitle",
          property: "og:title",
          content: this.$t("layout.html.title"),
        },
        {
          hid: "ogsitename",
          property: "og:site_name",
          content: this.$t("layout.html.title"),
        },
        {
          hid: "ogdescription",
          property: "og:description",
          content: this.$t("layout.html.description"),
        },
        {
          hid: "itempropname",
          itemprop: "name",
          content: this.$t("layout.html.title"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
