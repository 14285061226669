import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';
/**
 * Magazine store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadMagazine: "magazine/LOAD_MAGAZINE",
      saveMagazine: "magazine/SAVE_MAGAZINE",
      saveTemplateMagazine: "magazine/SAVE_TEMPLATE_MAGAZINE",
      saveMagazineFlatplan: "magazine/SAVE_MAGAZINE_FLATPLAN",
      changeOwnerMagazine: "magazine/CHANGE_OWNER_MAGAZINE",
      deleteMagazine: "magazine/DELETE_MAGAZINE",
      resetAllMagazineDependency: "magazine/RESET_ALL_MAGAZINE_DEPENDENCY",
      loadMagazineForEditing: "magazine/LOAD_MAGAZINE_FOR_EDITING",
      loadMagazineDeletedPages:"magazine/LOAD_MAGAZINE_DELETED_PAGES",
      loadUserMagazines: "magazine/LOAD_USER_MAGAZINES",
      loadOrganizationMagazines: "magazine/LOAD_ORGANIZATION_MAGAZINES",
      softdeleteMagazine: "magazine/SOFTDELETE_MAGAZINE",
      duplicateTemplate: "magazine/DUPLICATE_TEMPLATE",
      updateClientMagazine: "magazine/UPDATE_CLIENT_MAGAZINE",
      setDeletedClientMagazine: "magazine/SET_DELETED_CLIENT_MAGAZINE",
      addPagesFromTemplate: "magazine/ADD_PAGES_FROM_TEMPLATE",
      addEmptyPage: "magazine/ADD_EMPTY_PAGE",
      removePages: "magazine/REMOVE_PAGES",
      copyPages: "magazine/COPY_PAGES",
      restorePages: "magazine/RESTORE_PAGES",
      loadCreateFromTemplateMagazines: "magazine/LOAD_CREATE_FROM_TEMPLATE_MAGAZINES",
      loadTemplateMagazines: "magazine/LOAD_TEMPLATE_MAGAZINES",
      countTemplateMagazines: "magazine/COUNT_TEMPLATE_MAGAZINES",
      loadTemplate: "magazine/LOAD_TEMPLATE",
      loadLastTemplate: "magazine/LOAD_LAST_TEMPLATE",
      loadClientMagazines:"magazine/LOAD_CLIENT_MAGAZINES",
      countClientMagazines:"magazine/COUNT_CLIENT_MAGAZINES",
      exportClientMagazines:"magazine/EXPORT_CLIENT_MAGAZINES",
      requestExportMagazinePdf: "magazine/REQUEST_EXPORT_MAGAZINE_PDF",
      requestExportMagazinePdfStatus: "magazine/REQUEST_EXPORT_MAGAZINE_PDF_STATUS",
      publishMagazine: "magazine/PUBLISH_MAGAZINE",
      proceedUploadViewerLogo:"magazine/PROCEED_UPLOAD_VIEWER_LOGO",
    }),
    ...mapMutations({
      updateMagazineField: "magazine/UPDATE_FIELD",
      updateMagazineRootField: "magazine/UPDATE_ROOT_FIELD",
      updateMagazineSettings: "magazine/UPDATE_SETTINGS",
      updateMagazineArray: "magazine/UPDATE_ARRAY",
      moveMagazineArray: "magazine/MOVE_ARRAY",
      updateMagazineRootArray: "magazine/UPDATE_ROOT_ARRAY",
      addMagazine:"magazine/ADD_MAGAZINE",
      updateMagazine:"magazine/UPDATE_MAGAZINE",
      removeMagazine:"magazine/REMOVE_MAGAZINE",
      resetMagazine:"magazine/RESET_MAGAZINE",
      resetStateMagazine:"magazine/RESET_STATE",
      updateCurrentPageOrder:"magazine/UPDATE_CURRENT_PAGE_ORDER",
      setClientMagazinesCount:"magazine/SET_CLIENT_MAGAZINES_COUNT",
      setTemplateMagazinesCount:"magazine/SET_TEMPLATE_MAGAZINES_COUNT",
      resetPdfStatus:"magazine/RESET_PDF_STATUS_STATE"
    }),
    },

      computed: {
    ...mapState("magazine", ["magazineForEditingLoadComplete","magazine","magazineDeletedPages","template","userMagazines","organizationMagazines","templateMagazines","clientMagazines","clientMagazinesCount","templateMagazinesCount","pdfStatus"]),
    ...mapGetters("magazine", [
                                "getDefaultMagazine","getDraftUserMagazines","getPublishUserMagazines","getCollaborationUserMagazines",
                                "checkMagazinePerm", "pageIndexMap","pageNameMap","magazinePages"
                              ]),
      }
 */
/**
 * return default magazine object
 */
function initialState() {
  return {
    _id: null,
    name: '',
    description: '',
    owner: null, // references model User
    workspace: null,
    issue: '',
    version: 1,
    isPrivate: false,
    published: null,
    status: 'New', // values  in [New, In progress, Published, Deleted]
    deleted: false,
    tags: [],
    category: '',
    format: null, // references model Format
    themeList: [], // references model Theme
    theme: null, // references model Theme
    themeExtended: null, // references model Theme
    isTemplate: false,
    template: null, // references model Magazine
    pageOrder: [], // references model Page,
    thumb: '',
    collaboration: null, // reference model Collaboration
    library: null, // references model Library,
    settings: null,
    lastVisualChangeAt: null,
    exportedPdfs: null,
    googleAnalyticsId: '',
    matomoAnalyticsId: '',
    matomoAnalyticsUrl: '',
    viewerIsIndexable: false,
    otherIssues: null,
    viewerShowDownload: false,
    sendWarningBeforeDeleteAt: null,
    viewerViewCounter: 0,
    viewerSpecificLogo: '',
    viewerSpecificLogoLink: '',
    collaboratorCount: 0,
    pageCount: 0,
    bonusNbCollaborators: 0,
    activeOffer: null,
    offers: [],
    userPermissions: [],
    recursiveActiveOffer: null
  };
}

const initialSettings = {
  footer: {
    left: null,
    right: null,
  },
  backgroundFillSwatchName: null
}

function initialPdfStatusState() {
  return {
    id: null,
    version: '',
    startAt: 0,
    pdfProgressState: ''
  }
}

function _formatMagazineForList(magazine_) {
  return {
    _id: magazine_._id,
    name: magazine_.name,
    issue: magazine_.issue,
    published: magazine_.published,
    format: magazine_.format._id,
    template: magazine_.template,
    isTemplate: magazine_.isTemplate,
    isPrivate: magazine_.isPrivate,
    status: magazine_.status,
    tags: magazine_.tags,
    theme: magazine_.theme,
    themeList: magazine_.themeList,
    createdAt: magazine_.createdAt,
    updatedAt: magazine_.updatedAt,
    thumb: magazine_.thumb,
    collaboration: magazine_.collaboration
  };
}

const getDefaultState = () => {
  return {
    magazineForEditingLoadComplete: false,
    magazineLoadType: '',
    magazine: initialState(),
    magazineDeletedPages: null,
    template: initialState(),
    userMagazines: null,
    organizationMagazines: null,
    templateMagazines: null,
    templateMagazinesCount: 0,
    clientMagazines: null,
    clientMagazinesCount: 0,
    pdfStatus: initialPdfStatusState()
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  /**
   * return defaut state of magazine object
   */
  getDefaultMagazine: state => initialState(),

  getDraftUserMagazines: state => {
    return state.userMagazines === null
      ? []
      : state.userMagazines
        .filter(item_ => item_.published === null)
        .sort((a, b) => {
          if (a.lastVisualChangeAt < b.lastVisualChangeAt) return 1;
          if (a.lastVisualChangeAt > b.lastVisualChangeAt) return -1;
          else return 0;
        });
  },
  getPublishUserMagazines: state => {
    return state.userMagazines === null
      ? []
      : state.userMagazines
        .filter(item_ => item_.published !== null)
        .sort((a, b) => {
          if (a.published < b.published) return 1;
          if (a.published > b.published) return -1;
          else return 0;
        });
  },
  getCollaborationUserMagazines: state => {
    return state.userMagazines === null
      ? []
      : state.userMagazines
        .filter(item_ => item_.published === null && item_.collaboration)
        .sort((a, b) => {
          if (a.lastVisualChangeAt < b.lastVisualChangeAt) return 1;
          if (a.lastVisualChangeAt > b.lastVisualChangeAt) return -1;
          else return 0;
        });
  },

  checkMagazinePerm: state => permission => {
    return (state.magazine
      && state.magazine.userPermissions
      && state.magazine.userPermissions.indexOf(permission) >= 0);
  },

  magazinePages: (state, getters) => {
    if (state.magazine._id === null || !state.magazine.pageOrder) {
      return [];
    }
    let currentPageIndex = 0;
    const _list = [...state.magazine.pageOrder].reduce(
      (list, page, index) => {
        // insert empty missing page for complete double page
        if (page.double && currentPageIndex % 2 === 0) {
          // list.push({
          //   _id: currentPageIndex,
          //   isEmpty: true,
          //   isMissing: true,
          //   pageOrderMissingIndex: index
          // });
          currentPageIndex++;
          page.missingPreviousPage = true;
        }
        list.push(page);
        currentPageIndex += page.double ? 2 : 1;

        return list;
      },
      []
    )

    return _list;
  },
  pageIndexMap: (state, getters) => {
    let number = 1;
    return getters.magazinePages.reduce((list, page) => {
      if (!page.isEmpty || page.isMissing) {
        list[page._id] = page.double ? number + "-" + (number + 1) : `${number}`;
        number += page.double ? 2 : 1;
      }
      return list;
    }, {});
  },
  pageNameMap: (state, getters) => {
    let number = 1;
    return getters.magazinePages.reduce((list, page) => {
      if (!page.isEmpty || page.isMissing) {
        const pageNumber = page.double ? number + "-" + (number + 1) : `${number}`;
        if (page.name && page.name.length > 0) {
          list[page._id] = pageNumber + ' - ' + page.name;
          if (page.double) {
            // add specific page name by single page
            list[page._id + "_left"] = number + ' - ' + page.name + " (left)";
            list[page._id + "_right"] = (number + 1) + ' - ' + page.name + " (right)";
          }
        } else if (page.double) {
          list[page._id] = "Pages " + pageNumber;
          // add specific page name by single page
          list[page._id + "_left"] = "Page " + number + " (left)";
          list[page._id + "_right"] = "Page " + (number + 1) + " (right)";

        } else {
          list[page._id] = "Page " + pageNumber;
        }

        number += page.double ? 2 : 1;
      }
      return list;
    }, {});
  },
};

// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add magazine object in collection from payload.magazine parameter
   */
  ADD_MAGAZINE: (state, payload) => {
    const _magazine = payload.magazine,
      _magazineFormated = _formatMagazineForList(_magazine);

    if (state.userMagazines !== null && _magazine.deleted === false && _magazine.isTemplate === false) {
      state.userMagazines.push(_magazineFormated);
    }
    if (state.templateMagazines !== null && _magazine.deleted === false && _magazine.isTemplate === true) {
      state.templateMagazines.push(_magazineFormated);
    }
  },
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update magazine object in collection from payload.magazine parameter
   */
  UPDATE_MAGAZINE: (state, payload) => {
    const _magazine = payload.magazine,
      _magazineID = _magazine._id,
      _magazineFormated = _formatMagazineForList(_magazine),
      _indexUserMagazines =
        state.userMagazines !== null ? state.userMagazines.findIndex(magazine => magazine._id === _magazineID) : -1,
      _indexTemplateMagazines =
        state.templateMagazines !== null
          ? state.templateMagazines.findIndex(magazine => magazine._id === _magazineID)
          : -1,
      _indexClientMagazines =
        state.clientMagazines !== null ? state.clientMagazines.findIndex(magazine => magazine._id === _magazineID) : -1;

    if (_indexUserMagazines >= 0) {
      if (_magazine.deleted === false && _magazine.isTemplate === false) {
        Object.assign(state.userMagazines[_indexUserMagazines], _magazineFormated);
      } else {
        state.userMagazines.splice(_indexUserMagazines, 1);
      }
    }
    if (_indexTemplateMagazines >= 0) {
      if (_magazine.deleted === false && _magazine.isTemplate === true) {
        Object.assign(state.templateMagazines[_indexTemplateMagazines], _magazineFormated);
      } else {
        state.templateMagazines.splice(_indexTemplateMagazines, 1);
      }
    }
    if (_indexClientMagazines >= 0) {
      Object.assign(state.clientMagazines[_indexClientMagazines], _magazine);
    }

    // update state.template if equals to magazine
    if (state.template._id === _magazine._id) {
      Utils.setProperty('template', state, _magazine);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} magazineID
   *
   * remove magazine object from collection with magazineID
   */
  REMOVE_MAGAZINE: (state, magazineID) => {
    const _indexUserMagazines =
      state.userMagazines !== null ? state.userMagazines.findIndex(magazine => magazine._id === magazineID) : -1,
      _indexTemplateMagazines =
        state.templateMagazines !== null
          ? state.templateMagazines.findIndex(magazine => magazine._id === magazineID)
          : -1,
      _indexClientMagazines =
        state.clientMagazines !== null ? state.clientMagazines.findIndex(magazine => magazine._id === magazineID) : -1;

    if (_indexUserMagazines >= 0) {
      state.userMagazines.splice(_indexUserMagazines, 1);
    }
    if (_indexTemplateMagazines >= 0) {
      state.templateMagazines.splice(_indexTemplateMagazines, 1);
    }
    if (_indexClientMagazines >= 0) {
      state.clientMagazines.splice(_indexClientMagazines, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.magazine
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.magazine, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  UPDATE_SETTINGS: (state, { key, value }) => {
    if (!state.magazine.settings) {
      state.magazine.settings = initialSettings;
    }
    Utils.setProperty(key, state.magazine.settings, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.magazine
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb
        ? state.magazine[payload.key].findIndex(payload.cb)
        : state.magazine[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.magazine[payload.key].splice(_index, 1);
      }
    } else {
      state.magazine[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * move element index of state.magazine
   */
  MOVE_ARRAY: (state, payload) => {
    // let oldVal = state.magazine[payload.key][payload.from];
    // state.magazine[payload.key][payload.from] = state.magazine[payload.key][payload.to];
    // Vue.set(state.magazine[payload.key], payload.to, oldVal);
    state.magazine[payload.key].splice(payload.to, 0, state.magazine[payload.key].splice(payload.from, 1)[0]);

    if (payload.key === 'pageOrder' && payload.to === 0) {
      state.magazine.thumb = state.magazine.pageOrder[0] && state.magazine.pageOrder[0]._id ? state.magazine.pageOrder[0]._id : state.magazine.pageOrder[0];
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state
   */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    } else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.magazine
   */
  RESET_MAGAZINE: state => {
    state.magazine = Object.assign({}, initialState());
  },

  RESET_TEMPLATE: state => {
    state.template = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },

  UPDATE_CURRENT_PAGE_ORDER: (state, { pageId, value }) => {
    let _pageIndex = state.magazine.pageOrder.findIndex(page_ => page_._id === pageId);
    if (_pageIndex >= 0) {
      state.magazine.pageOrder[_pageIndex] = Object.assign(state.magazine.pageOrder[_pageIndex], value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} payload - { count }
   *
   * set count data client magazine
   */
  SET_CLIENT_MAGAZINES_COUNT: (state, { count }) => {
    state.clientMagazinesCount = count;
  },

  SET_TEMPLATE_MAGAZINES_COUNT: (state, { count }) => {
    state.templateMagazinesCount = count;
  },

  RESET_PDF_STATUS_STATE: (state) => {
    state.pdfStatus = Object.assign({}, initialPdfStatusState());
  }
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch current magazine object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} magazineID
   * @return Promise
   */
  LOAD_MAGAZINE: async function (context, { magazineId, loadType, incrementNbView, forceReload }) {
    console.log('LOAD_MAGAZINE ' + magazineId + ' - ' + loadType)
    if (loadType && loadType == 'current') {
      forceReload = true;
      loadType = context.state.magazineLoadType;
    }

    const magazineLoadType = loadType && ['client', 'public'].indexOf(loadType) >= 0 ? loadType : '';
    if (magazineId !== context.state.magazine._id || magazineLoadType !== context.state.magazineLoadType) {
      this.commit('magazine/RESET_MAGAZINE');
    } else if (!forceReload) {
      console.log('LOAD_MAGAZINE already in state ' + magazineId + ' - ' + loadType);
      return Promise.resolve(true);
    }

    let apiUrl = '/api/v1/magazine/';
    if (magazineLoadType === 'client') {
      apiUrl = '/api/v1/magazine/client/show/';
    } else if (magazineLoadType === 'public') {
      apiUrl = '/api/v1/magazine/public/show/';
    }

    return this.$axios
      .get(apiUrl + Utils.normalizePayloadURI(magazineId, 'magazineId') + (incrementNbView ? '?incrementNbView=1' : ''))
      .then(r => r.data)
      .then(
        magazine => {
          this.commit('magazine/UPDATE_ROOT_FIELD', { key: 'magazineLoadType', value: magazineLoadType });

          this.commit('magazine/UPDATE_FIELD', { key: 'name', value: magazine.name });
          this.commit('magazine/UPDATE_FIELD', { key: 'description', value: magazine.description });
          this.commit('magazine/UPDATE_FIELD', { key: 'owner', value: magazine.owner });
          this.commit('magazine/UPDATE_FIELD', { key: 'workspace', value: magazine.workspace });
          this.commit('magazine/UPDATE_FIELD', { key: 'issue', value: magazine.issue });
          this.commit('magazine/UPDATE_FIELD', { key: 'version', value: magazine.version });
          this.commit('magazine/UPDATE_FIELD', { key: 'isPrivate', value: magazine.isPrivate });
          this.commit('magazine/UPDATE_FIELD', { key: 'published', value: magazine.published });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'deleted', value: magazine.deleted });
          this.commit('magazine/UPDATE_FIELD', { key: 'tags', value: magazine.tags });
          this.commit('magazine/UPDATE_FIELD', { key: 'category', value: magazine.category });
          this.commit('magazine/UPDATE_FIELD', { key: 'format', value: magazine.format });
          this.commit('magazine/UPDATE_FIELD', { key: 'themeList', value: magazine.themeList });
          this.commit('magazine/UPDATE_FIELD', { key: 'theme', value: magazine.theme });
          this.commit('magazine/UPDATE_FIELD', { key: 'themeExtended', value: magazine.themeExtended });
          this.commit('magazine/UPDATE_FIELD', { key: 'isTemplate', value: magazine.isTemplate });
          this.commit('magazine/UPDATE_FIELD', { key: 'template', value: magazine.template });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'thumb', value: magazine.thumb });
          this.commit('magazine/UPDATE_FIELD', { key: 'collaboration', value: magazine.collaboration });
          this.commit('magazine/UPDATE_FIELD', { key: 'library', value: magazine.library });
          this.commit('magazine/UPDATE_FIELD', { key: 'settings', value: magazine.settings });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'exportedPdfs', value: magazine.exportedPdfs });
          this.commit('magazine/UPDATE_FIELD', { key: 'googleAnalyticsId', value: magazine.googleAnalyticsId });
          this.commit('magazine/UPDATE_FIELD', { key: 'matomoAnalyticsId', value: magazine.matomoAnalyticsId });
          this.commit('magazine/UPDATE_FIELD', { key: 'matomoAnalyticsUrl', value: magazine.matomoAnalyticsUrl });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerIsIndexable', value: magazine.viewerIsIndexable });
          this.commit('magazine/UPDATE_FIELD', { key: 'otherIssues', value: magazine.otherIssues });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerShowDownload', value: magazine.viewerShowDownload });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerViewCounter', value: magazine.viewerViewCounter });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerSpecificLogo', value: magazine.viewerSpecificLogo });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerSpecificLogoLink', value: magazine.viewerSpecificLogoLink });
          this.commit('magazine/UPDATE_FIELD', { key: 'collaboratorCount', value: magazine.collaboratorCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'bonusNbCollaborators', value: magazine.bonusNbCollaborators });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageCount', value: magazine.pageCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'createdAt', value: magazine.createdAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'updatedAt', value: magazine.updatedAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'activeOffer', value: magazine.activeOffer });
          this.commit('magazine/UPDATE_FIELD', { key: 'offers', value: magazine.offers });
          this.commit('magazine/UPDATE_FIELD', { key: 'userPermissions', value: magazine.userPermissions });
          this.commit('magazine/UPDATE_FIELD', { key: 'recursiveActiveOffer', value: magazine.recursiveActiveOffer });
          this.commit('magazine/UPDATE_FIELD', { key: '_id', value: magazine._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'magazine is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * upsert current template magazine object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_TEMPLATE_MAGAZINE: function (context, payload) {
    let method = context.state.magazine._id === null ? 'post' : 'put';
    let updateId = context.state.magazine._id === null ? '' : context.state.magazine._id;

    return this.$axios[method]('/api/v1/magazine/template/' + updateId, context.state.magazine)
      .then(r => r.data)
      .then(
        magazine => {
          this.commit('magazine/UPDATE_FIELD', { key: '_id', value: magazine._id });
          this.commit('magazine/UPDATE_FIELD', { key: 'name', value: magazine.name });
          this.commit('magazine/UPDATE_FIELD', { key: 'description', value: magazine.description });
          this.commit('magazine/UPDATE_FIELD', { key: 'owner', value: magazine.owner });
          this.commit('magazine/UPDATE_FIELD', { key: 'workspace', value: magazine.workspace });
          this.commit('magazine/UPDATE_FIELD', { key: 'issue', value: magazine.issue });
          this.commit('magazine/UPDATE_FIELD', { key: 'isPrivate', value: magazine.isPrivate });
          this.commit('magazine/UPDATE_FIELD', { key: 'version', value: magazine.version });
          this.commit('magazine/UPDATE_FIELD', { key: 'published', value: magazine.published });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'deleted', value: magazine.deleted });
          this.commit('magazine/UPDATE_FIELD', { key: 'tags', value: magazine.tags });
          this.commit('magazine/UPDATE_FIELD', { key: 'category', value: magazine.category });
          this.commit('magazine/UPDATE_FIELD', { key: 'format', value: magazine.format });
          this.commit('magazine/UPDATE_FIELD', { key: 'themeList', value: magazine.themeList });
          this.commit('magazine/UPDATE_FIELD', { key: 'theme', value: magazine.theme });
          this.commit('magazine/UPDATE_FIELD', { key: 'themeExtended', value: magazine.themeExtended });
          this.commit('magazine/UPDATE_FIELD', { key: 'isTemplate', value: magazine.isTemplate });
          this.commit('magazine/UPDATE_FIELD', { key: 'template', value: magazine.template });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'library', value: magazine.library });
          this.commit('magazine/UPDATE_FIELD', { key: 'thumb', value: magazine.thumb });
          this.commit('magazine/UPDATE_FIELD', { key: 'collaboration', value: magazine.collaboration });
          this.commit('magazine/UPDATE_FIELD', { key: 'settings', value: magazine.settings });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'exportedPdfs', value: magazine.exportedPdfs });
          this.commit('magazine/UPDATE_FIELD', { key: 'googleAnalyticsId', value: magazine.googleAnalyticsId });
          this.commit('magazine/UPDATE_FIELD', { key: 'matomoAnalyticsId', value: magazine.matomoAnalyticsId });
          this.commit('magazine/UPDATE_FIELD', { key: 'matomoAnalyticsUrl', value: magazine.matomoAnalyticsUrl });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerIsIndexable', value: magazine.viewerIsIndexable });
          this.commit('magazine/UPDATE_FIELD', { key: 'otherIssues', value: magazine.otherIssues });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerShowDownload', value: magazine.viewerShowDownload });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerViewCounter', value: magazine.viewerViewCounter });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerSpecificLogo', value: magazine.viewerSpecificLogo });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerSpecificLogoLink', value: magazine.viewerSpecificLogoLink });
          this.commit('magazine/UPDATE_FIELD', { key: 'collaboratorCount', value: magazine.collaboratorCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'bonusNbCollaborators', value: magazine.bonusNbCollaborators });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageCount', value: magazine.pageCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'activeOffer', value: magazine.activeOffer });
          this.commit('magazine/UPDATE_FIELD', { key: 'userPermissions', value: magazine.userPermissions });
          this.commit('magazine/UPDATE_FIELD', { key: 'recursiveActiveOffer', value: magazine.recursiveActiveOffer });
          this.commit('magazine/UPDATE_FIELD', { key: 'offers', value: magazine.offers });

          if (updateId.length === 0) {
            this.commit('magazine/ADD_MAGAZINE', { magazine });
          } else {
            this.commit('magazine/UPDATE_MAGAZINE', { magazine });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'magazine is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          if (updateId.length > 0) {
            // reload magazine if error (maybe store is not same as server state)
            this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: updateId, loadType: 'current' })
          }

          return false;
        }
      );
  },

  /**
   * save current magazine object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_MAGAZINE: function (context, payload) {
    let method = context.state.magazine._id === null ? 'post' : 'put';
    let updateId = context.state.magazine._id === null ? '' : context.state.magazine._id;

    return this.$axios[method]('/api/v1/magazine/' + updateId, context.state.magazine)
      .then(r => r.data)
      .then(
        magazine => {
          // specific case, waiting checkout before save new magazine
          if (magazine.checkout) {
            return { checkout: magazine.checkout };
          }

          this.commit('magazine/UPDATE_FIELD', { key: '_id', value: magazine._id });
          this.commit('magazine/UPDATE_FIELD', { key: 'name', value: magazine.name });
          this.commit('magazine/UPDATE_FIELD', { key: 'description', value: magazine.description });
          this.commit('magazine/UPDATE_FIELD', { key: 'owner', value: magazine.owner });
          this.commit('magazine/UPDATE_FIELD', { key: 'workspace', value: magazine.workspace });
          this.commit('magazine/UPDATE_FIELD', { key: 'issue', value: magazine.issue });
          this.commit('magazine/UPDATE_FIELD', { key: 'isPrivate', value: magazine.isPrivate });
          this.commit('magazine/UPDATE_FIELD', { key: 'version', value: magazine.version });
          this.commit('magazine/UPDATE_FIELD', { key: 'published', value: magazine.published });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'deleted', value: magazine.deleted });
          this.commit('magazine/UPDATE_FIELD', { key: 'tags', value: magazine.tags });
          this.commit('magazine/UPDATE_FIELD', { key: 'category', value: magazine.category });
          this.commit('magazine/UPDATE_FIELD', { key: 'format', value: magazine.format });
          this.commit('magazine/UPDATE_FIELD', { key: 'themeList', value: magazine.themeList });
          this.commit('magazine/UPDATE_FIELD', { key: 'theme', value: magazine.theme });
          this.commit('magazine/UPDATE_FIELD', { key: 'themeExtended', value: magazine.themeExtended });
          this.commit('magazine/UPDATE_FIELD', { key: 'isTemplate', value: magazine.isTemplate });
          this.commit('magazine/UPDATE_FIELD', { key: 'template', value: magazine.template });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'library', value: magazine.library });
          this.commit('magazine/UPDATE_FIELD', { key: 'thumb', value: magazine.thumb });
          this.commit('magazine/UPDATE_FIELD', { key: 'collaboration', value: magazine.collaboration });
          this.commit('magazine/UPDATE_FIELD', { key: 'settings', value: magazine.settings });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'exportedPdfs', value: magazine.exportedPdfs });
          this.commit('magazine/UPDATE_FIELD', { key: 'googleAnalyticsId', value: magazine.googleAnalyticsId });
          this.commit('magazine/UPDATE_FIELD', { key: 'matomoAnalyticsId', value: magazine.matomoAnalyticsId });
          this.commit('magazine/UPDATE_FIELD', { key: 'matomoAnalyticsUrl', value: magazine.matomoAnalyticsUrl });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerIsIndexable', value: magazine.viewerIsIndexable });
          this.commit('magazine/UPDATE_FIELD', { key: 'otherIssues', value: magazine.otherIssues });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerShowDownload', value: magazine.viewerShowDownload });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerViewCounter', value: magazine.viewerViewCounter });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerSpecificLogo', value: magazine.viewerSpecificLogo });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerSpecificLogoLink', value: magazine.viewerSpecificLogoLink });
          this.commit('magazine/UPDATE_FIELD', { key: 'collaboratorCount', value: magazine.collaboratorCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'bonusNbCollaborators', value: magazine.bonusNbCollaborators });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageCount', value: magazine.pageCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'activeOffer', value: magazine.activeOffer });
          this.commit('magazine/UPDATE_FIELD', { key: 'userPermissions', value: magazine.userPermissions });
          this.commit('magazine/UPDATE_FIELD', { key: 'recursiveActiveOffer', value: magazine.recursiveActiveOffer });
          this.commit('magazine/UPDATE_FIELD', { key: 'offers', value: magazine.offers });

          if (updateId.length === 0) {
            this.commit('magazine/ADD_MAGAZINE', { magazine });
          } else {
            this.commit('magazine/UPDATE_MAGAZINE', { magazine });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'magazine is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          if (updateId.length > 0) {
            // reload magazine if error (maybe store is not same as server state)
            this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: updateId, loadType: 'current' })
          }

          return false;
        }
      );
  },


  /**
   * save current magazine pageOrder object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_MAGAZINE_FLATPLAN: function (context, payload) {

    return this.$axios.put('/api/v1/magazine/flatplan/' + context.state.magazine._id,
      { pageOrder: context.state.magazine.pageOrder }
    )
      .then(r => r.data)
      .then(
        magazine => {
          console.log('SAVE_MAGAZINE_FLATPLAN success');

          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'exportedPdfs', value: magazine.exportedPdfs });

          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.magazine.flatplanIsSaved' });

          return true;
        },
        err => {
          console.log('SAVE_MAGAZINE_FLATPLAN error');

          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          // reload magazine if error (maybe store is not same as server state)
          this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: context.state.magazine._id, loadType: 'current' })

          return false;
        }
      );
  },

  /**
   * duplicate a magazine
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  DUPLICATE_TEMPLATE: function (context, payload) {

    return this.$axios.post('/api/v1/magazine/duplicateTemplate/' + payload.id)
      .then(r => r.data)
      .then(
        magazine => {
          this.commit('magazine/UPDATE_FIELD', { key: '_id', value: magazine._id });
          this.commit('magazine/UPDATE_FIELD', { key: 'name', value: magazine.name });
          this.commit('magazine/UPDATE_FIELD', { key: 'description', value: magazine.description });
          this.commit('magazine/UPDATE_FIELD', { key: 'owner', value: magazine.owner });
          this.commit('magazine/UPDATE_FIELD', { key: 'workspace', value: magazine.workspace });
          this.commit('magazine/UPDATE_FIELD', { key: 'issue', value: magazine.issue });
          this.commit('magazine/UPDATE_FIELD', { key: 'isPrivate', value: magazine.isPrivate });
          this.commit('magazine/UPDATE_FIELD', { key: 'version', value: magazine.version });
          this.commit('magazine/UPDATE_FIELD', { key: 'published', value: magazine.published });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'deleted', value: magazine.deleted });
          this.commit('magazine/UPDATE_FIELD', { key: 'tags', value: magazine.tags });
          this.commit('magazine/UPDATE_FIELD', { key: 'category', value: magazine.category });
          this.commit('magazine/UPDATE_FIELD', { key: 'format', value: magazine.format });
          this.commit('magazine/UPDATE_FIELD', { key: 'themeList', value: magazine.themeList });
          this.commit('magazine/UPDATE_FIELD', { key: 'theme', value: magazine.theme });
          this.commit('magazine/UPDATE_FIELD', { key: 'themeExtended', value: magazine.themeExtended });
          this.commit('magazine/UPDATE_FIELD', { key: 'isTemplate', value: magazine.isTemplate });
          this.commit('magazine/UPDATE_FIELD', { key: 'template', value: magazine.template });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'library', value: magazine.library });
          this.commit('magazine/UPDATE_FIELD', { key: 'thumb', value: magazine.thumb });
          this.commit('magazine/UPDATE_FIELD', { key: 'collaboration', value: magazine.collaboration });
          this.commit('magazine/UPDATE_FIELD', { key: 'settings', value: magazine.settings });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'exportedPdfs', value: magazine.exportedPdfs });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });

          this.commit('magazine/ADD_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'magazine is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          if (updateId.length > 0) {
            // reload magazine if error (maybe store is not same as server state)
            this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: updateId, loadType: 'current' })
          }

          return false;
        }
      );
  },

  CHANGE_OWNER_MAGAZINE: function (context, { ownerId }) {
    let updateId = context.state.magazine._id === null ? '' : context.state.magazine._id;

    return this.$axios.put('/api/v1/magazine/owner/' + updateId, { owner: ownerId })
      .then(r => r.data)
      .then(
        magazine => {
          this.commit('magazine/UPDATE_FIELD', { key: 'owner', value: magazine.owner });
          this.commit('magazine/UPDATE_FIELD', { key: 'workspace', value: magazine.workspace });
          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'magazine is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          if (updateId.length > 0) {
            // reload magazine if error (maybe store is not same as server state)
            this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: updateId, loadType: 'current' })
          }

          return false;
        }
      );
  },

  /**
   * delete current magazine object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  DELETE_MAGAZINE: function (context, payload) {
    let id = payload.id || context.state.magazine._id;

    return this.$axios
      .delete('/api/v1/magazine/' + id)
      .then(r => r.data)
      .then(
        magazine => {
          this.commit('magazine/REMOVE_MAGAZINE', id);
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'magazine has been deleted' });

          this.commit('magazine/RESET_MAGAZINE');
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  RESET_ALL_MAGAZINE_DEPENDENCY: function (context) {
    console.log('RESET_ALL_MAGAZINE_DEPENDENCY');
    this.commit('theme/RESET_THEME');
    this.commit('library/RESET_LIBRARY');
    this.commit('palette/RESET_PALETTE');
    this.commit('magazine/RESET_MAGAZINE');
    this.commit('magazine/UPDATE_ROOT_FIELD', {
      key: 'magazineDeletedPages',
      value: null
    });
    this.commit('collaboration/RESET_STATE');
    this.commit('order/RESET_GELATO_QUOTE');
    this.commit('fabric/RESET_STATE');
  },

  /**
   * fetch current magazine object with all needed dependancy for editing
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} magazineId
   * @return Promise
   */
  LOAD_MAGAZINE_FOR_EDITING: async function (context, { magazineId, paletteId, resetStore }) {
    let _promises = [];

    this.commit('magazine/UPDATE_ROOT_FIELD', {
      key: 'magazineForEditingLoadComplete',
      value: false
    });

    console.log('LOAD_MAGAZINE_FOR_EDITING / resetstore:' + resetStore + ' / previousMag ' + context.state.magazine._id + ' / currentMag ' + magazineId);

    // force reset all magazine dependancy for reload
    if (resetStore || context.state.magazine._id !== magazineId) {
      this.dispatch('magazine/RESET_ALL_MAGAZINE_DEPENDENCY');
    }

    // no need to have complet user magazine list for editing a specific magazine ?!?
    // test comment load user magazines
    //_promises.push(this.dispatch('magazine/LOAD_USER_MAGAZINES'));

    // load magazine, theme and palette
    _promises.push(
      new Promise((resolve, reject) => {
        this.dispatch('magazine/LOAD_MAGAZINE', { magazineId }).then(async (success) => {
          if (!success) {
            reject();
            return;
          }

          // load collaboration for check permission of user or set all collaboration permission to owner
          if (context.state.magazine.collaboration) {
            await this.dispatch('collaboration/LOAD_COLLABORATION', context.state.magazine.collaboration);
          } else {
            this.commit('collaboration/RESET_STATE');
          }

          // load template, themes and palettes
          await this.dispatch('magazine/LOAD_TEMPLATE', context.state.magazine.template);
          await this.dispatch('theme/LOAD_THEME', context.state.magazine.theme);
          await this.dispatch("palette/LOAD_THEME_PALETTES", context.rootState.theme.theme._id);
          // palette are now loaded by LOAD_THEME_PALETTES, just select the needed palette, no need to LOAD
          this.commit("palette/SET_CURRENT_PALETTE", paletteId ? paletteId : context.rootState.theme.theme.paletteList[0]._id);

          // set load complete
          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'magazineForEditingLoadComplete',
            value: true
          });

          resolve();
        });
      })
    );

    // load deleted page list if not already loaded
    if (context.state.magazineDeletedPages === null) {
      this.dispatch('magazine/LOAD_MAGAZINE_DELETED_PAGES', { magazineId });
    }

    return Promise.all(_promises);
  },

  LOAD_MAGAZINE_DELETED_PAGES: async function (context, { magazineId }) {
    const _filter = {
      magazine: magazineId,
      deleted: true
    };
    return this.$axios
      .get('/api/v1/page?filter=' + JSON.stringify(_filter))
      .then(r => r.data)
      .then(
        ({ pages }) => {

          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'magazineDeletedPages',
            value: pages
          });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'MAGAZINE DELETED PAGES are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch collection of user magazines
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_USER_MAGAZINES: async function (context) {
    const _filter = {
      deleted: false,
      isTemplate: false
    };

    return this.$axios
      .get('/api/v1/magazine?filter=' + JSON.stringify(_filter))
      .then(r => r.data)
      .then(
        magazines => {
          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'userMagazines',
            value: magazines
          });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'USER MAGAZINES are loaded'
          });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch collection of organization magazines
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_ORGANIZATION_MAGAZINES: async function (context, payload) {
    const organizationId = payload && payload.organizationId ? payload.organizationId : context.rootGetters['api/getCurrentOrganizationId'];
    if (!organizationId) {
      this.commit('magazine/UPDATE_ROOT_FIELD', {
        key: 'organizationMagazines',
        value: magazines
      });
      return Promise.reject('no current organization id');
    }

    const _filter = {
      deleted: false,
      isTemplate: false,
      organization: organizationId
    };

    return this.$axios
      .get('/api/v1/magazine?filter=' + JSON.stringify(_filter))
      .then(r => r.data)
      .then(
        magazines => {
          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'organizationMagazines',
            value: magazines
          });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'ORGANIZATION MAGAZINES are loaded'
          });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch collection of magazine template pages
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} templateId
   * @return Promise
   */
  LOAD_TEMPLATE: async function (context, payload) {
    let templateId;
    if (payload && typeof payload === 'object' && payload._id) {
      templateId = payload._id;
    } else {
      templateId = payload;
    }

    // reset template state if no template id
    if (!templateId) {
      this.commit('magazine/RESET_TEMPLATE');
      return Promise.resolve(true);
    }

    // don't reload template if already in state
    if (templateId === context.state.template._id) {
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/magazine/' + templateId + '?isTemplate=true')
      .then(r => r.data)
      .then(
        template => {
          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'template',
            value: template
          });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'TEMPLATE are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch last edited template
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @return Promise
   */
  LOAD_LAST_TEMPLATE: async function (context) {
    // const _filter = {
    //   deleted: false,
    //   isTemplate: true
    // };

    return (
      this.$axios
        // .get('/api/v1/magazine?filter=' + JSON.stringify(_filter) + '&sort=-updatedAt' + '&limit=1')
        .get('/api/v1/magazine/last?isTemplate=true')
        .then(r => r.data)
        .then(
          template => {
            this.commit('magazine/UPDATE_ROOT_FIELD', {
              key: 'template',
              value: template
            });
            this.dispatch('api/API_SUCCESS', { type: 'info', message: 'TEMPLATE are loaded' });

            return true;
          },
          err => {
            // don't dispatch error if no last template to show
            // this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
            return false;
          }
        )
    );
  },

  /**
   * delete current magazine object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SOFTDELETE_MAGAZINE: function (context, payload) {
    let id = payload.id || context.state.magazine._id;

    return this.$axios
      .put('/api/v1/magazine/' + id + '/softDelete', { deleted: true })
      .then(r => r.data)
      .then(
        magazine => {
          this.commit('magazine/REMOVE_MAGAZINE', id);
          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'magazine has been deleted'
          });

          this.commit('magazine/RESET_MAGAZINE');
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * save magazine object from admin dashboard
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  UPDATE_CLIENT_MAGAZINE: function (context, payload) {
    return this.$axios
      .put('/api/v1/magazine/clientUpdate/' + context.state.magazine._id,
        payload
      )
      .then(r => r.data)
      .then(
        magazine => {

          this.commit('magazine/UPDATE_FIELD', { key: 'category', value: magazine.category });
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerIsIndexable', value: magazine.viewerIsIndexable });
          this.commit('magazine/UPDATE_FIELD', { key: 'bonusNbCollaborators', value: magazine.bonusNbCollaborators });
          this.commit('magazine/UPDATE_FIELD', { key: 'activeOffer', value: magazine.activeOffer });
          this.commit('magazine/UPDATE_FIELD', { key: 'offers', value: magazine.offers });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'magazine has been updated'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * delete/restore current magazine object from admin dashboard
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SET_DELETED_CLIENT_MAGAZINE: function (context, payload) {
    let id = payload.id || context.state.magazine._id;

    return this.$axios
      .put('/api/v1/magazine/clientSetDeleted/' + id, { deleted: payload.deleted })
      .then(r => r.data)
      .then(
        magazine => {
          this.commit('magazine/UPDATE_FIELD', { key: 'deleted', value: magazine.deleted });

          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'magazine has been updated'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  ADD_EMPTY_PAGE: function (context, payload) {
    let id = context.state.magazine._id;

    return this.$axios
      .post('/api/v1/magazine/' + id + '/addEmptyPage/', { double: payload.double, index: payload.index })
      .then(r => r.data)
      .then(
        magazine => {
          this.commit('magazine/UPDATE_FIELD', { key: 'pageCount', value: magazine.pageCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });

          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'magazine has been updated'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          // reload magazine if error (maybe store is not same as server state)
          this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: id, loadType: 'current' })

          return false;
        }
      );
  },

  ADD_PAGES_FROM_TEMPLATE: function (context, payload) {
    let id = payload.id || context.state.magazine._id;

    return this.$axios
      .post('/api/v1/magazine/' + id + '/addFromTemplate/', {
        pages: payload.pages,
        index: payload.index
      })
      .then(r => r.data)
      .then(
        ({ magazine }) => {
          this.commit('magazine/UPDATE_FIELD', { key: 'pageCount', value: magazine.pageCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'thumb', value: magazine.thumb });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });

          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'magazine has been updated'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          // reload magazine if error (maybe store is not same as server state)
          this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: id, loadType: 'current' })

          return false;
        }
      );
  },

  REMOVE_PAGES: function (context, payload) {
    const id = context.state.magazine._id;

    return this.$axios
      .post('/api/v1/magazine/' + id + '/removePages/', {
        pageIds: payload.pageIds
      })
      .then(r => r.data)
      .then(
        magazine => {
          // add deleted page in magazineDeletedPages list
          payload.pageIds.forEach(pageId => {
            const page = Object.assign({}, context.state.magazine.pageOrder.find(item => item._id == pageId));
            page.deleted = true;

            this.commit('magazine/UPDATE_ROOT_ARRAY', { key: 'magazineDeletedPages', value: page });
          });

          this.commit('magazine/UPDATE_FIELD', { key: 'pageCount', value: magazine.pageCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });

          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'magazine has been updated'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          // reload magazine if error (maybe store is not same as server state)
          this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: id, loadType: 'current' })

          return false;
        }
      );
  },

  COPY_PAGES: function (context, payload) {
    const id = context.state.magazine._id;

    return this.$axios
      .post('/api/v1/magazine/' + id + '/copyPages/', payload)
      .then(r => r.data)
      .then(
        magazine => {

          this.commit('magazine/UPDATE_FIELD', { key: 'pageCount', value: magazine.pageCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });

          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'magazine has been updated'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          // reload magazine if error (maybe store is not same as server state)
          this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: id, loadType: 'current' })

          return false;
        }
      );
  },

  RESTORE_PAGES: function (context, payload) {
    const id = context.state.magazine._id;

    return this.$axios
      .post('/api/v1/magazine/' + id + '/restorePages/', {
        pageIds: payload.pageIds
      })
      .then(r => r.data)
      .then(
        magazine => {
          // remove deleted page from magazineDeletedPages list
          payload.pageIds.forEach(pageId => {
            this.commit('magazine/UPDATE_ROOT_ARRAY', { key: 'magazineDeletedPages', cb: item => item._id == pageId, delete: true });
          });

          this.commit('magazine/UPDATE_FIELD', { key: 'pageCount', value: magazine.pageCount });
          this.commit('magazine/UPDATE_FIELD', { key: 'pageOrder', value: magazine.pageOrder });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazine.lastVisualChangeAt });
          this.commit('magazine/UPDATE_FIELD', { key: 'sendWarningBeforeDeleteAt', value: magazine.sendWarningBeforeDeleteAt });

          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'magazine has been updated'
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          // reload magazine if error (maybe store is not same as server state)
          this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: id, loadType: 'current' })

          return false;
        }
      );
  },

  /**
 * fetch collection of template magazines for base to creta a new magazine
 * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
 * @param {String} query - see more https://github.com/loris/api-query-params
 * @return Promise
 */
  LOAD_CREATE_FROM_TEMPLATE_MAGAZINES: async function (context, payload) {

    return this.$axios
      .get('/api/v1/magazine/template/listCreateFrom')
      .then(r => r.data)
      .then(
        magazines_ => {
          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'templateMagazines',
            value: magazines_
          });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'TEMPLATE MAGAZINES are loaded'
          });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch collection of template magazines
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_TEMPLATE_MAGAZINES: async function (context, payload) {

    const _filter = Object.assign({
      deleted: false,
      isTemplate: true
    }, payload.filter);

    return this.$axios
      .get('/api/v1/magazine/template/list?filter=' + JSON.stringify(_filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        magazines_ => {
          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'templateMagazines',
            value: magazines_
          });

          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'TEMPLATE MAGAZINES are loaded'
          });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * fetch number of client magazines
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} query - see more https://github.com/loris/api-query-params
  * @return Promise
  */
  COUNT_TEMPLATE_MAGAZINES: async function (context, payload) {
    const _filter = Object.assign({
      deleted: false,
      isTemplate: true
    }, payload.filter);

    return this.$axios
      .get('/api/v1/magazine/template/count?filter=' + JSON.stringify(_filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('magazine/SET_TEMPLATE_MAGAZINES_COUNT', { count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT TEMPLATE MAGAZINES are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch collection of client magazines
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_CLIENT_MAGAZINES: async function (context, payload) {

    return this.$axios
      .get('/api/v1/magazine/client/list?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        magazines => {
          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'clientMagazines',
            value: magazines
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'MAGAZINES are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch number of client magazines
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  COUNT_CLIENT_MAGAZINES: async function (context, payload) {
    return this.$axios
      .get('/api/v1/magazine/client/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('magazine/SET_CLIENT_MAGAZINES_COUNT', { count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT CLIENT MAGAZINES are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * export client magazines as csv
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  EXPORT_CLIENT_MAGAZINES: async function (context, payload) {
    return this.$axios
      .get('/api/v1/magazine/client/export?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort)
      .then(r => r.data)
      .then(
        data => {
          let csvContent = "data:text/csv;charset=utf-8," + data;
          const link = document.createElement("a");
          link.setAttribute("href", encodeURI(csvContent));
          link.setAttribute("download", "magazines.csv");
          link.click();
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );;
  },

  REQUEST_EXPORT_MAGAZINE_PDF: async function (context, { magazineId, version, forceCleanAll, isAdminRequest, forceGenerateWithInvalidPage }) {
    this.commit('magazine/RESET_PDF_STATUS_STATE');

    return this.$axios
      .post('/api/v1/magazine/pdf/request', {
        magazineId,
        version,
        forceCleanAll,
        isAdminRequest,
        forceGenerateWithInvalidPage
      })
      .then(r => r.data)
      .then(
        ({ magazine, pdfStatus }) => {
          this.commit('magazine/UPDATE_FIELD', { key: 'exportedPdfs', value: magazine.exportedPdfs });
          this.commit('magazine/UPDATE_ROOT_FIELD', { key: 'pdfStatus', value: pdfStatus });

          return true;
        },
        err => {
          if (err.response.data && err.response.data.missingSvgs && err.response.data.missingSvgs.length > 0) {
            // specific case, ask client to regenerate all missing svg for pages
            return { missingSvgs: err.response.data.missingSvgs };
          } else {
            this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

            // reload magazine if error (maybe store is not same as server state)
            this.dispatch('magazine/LOAD_MAGAZINE', { magazineId, loadType: 'current' });

            return false;
          }
        });
  },

  REQUEST_EXPORT_MAGAZINE_PDF_STATUS: async function (context, { magazineId, version, updateStore }) {
    return this.$axios
      .post('/api/v1/magazine/pdf/request/status', {
        magazineId,
        version
      })
      .then(r => r.data)
      .then(
        ({ status }) => {
          if (updateStore && status) {
            this.commit('magazine/UPDATE_ROOT_FIELD', { key: 'pdfStatus', value: status });
          }

          return status;
        },
        err => {
          if (err.response.data && err.response.data.missingSvgs && err.response.data.missingSvgs.length > 0) {
            // specific case, ask client to regenerate all missing svg for pages
            return { missingSvgs: err.response.data.missingSvgs };
          } else {
            this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
            return false;
          }
        });
  },

  PUBLISH_MAGAZINE: function (context, { magazineId, publish }) {
    this.commit('magazine/RESET_PDF_STATUS_STATE');

    const id = magazineId ? magazineId : context.state.magazine._id;
    return this.$axios.put('/api/v1/magazine/' + id + '/publish', { publish })
      .then(r => r.data)
      .then(
        ({ magazine, pdfStatus }) => {
          //console.log('PUBLISH_MAGAZINE result ', magazine)
          this.commit('magazine/UPDATE_FIELD', { key: 'published', value: magazine.published });
          this.commit('magazine/UPDATE_FIELD', { key: 'status', value: magazine.status });
          this.commit('magazine/UPDATE_FIELD', { key: 'collaboration', value: magazine.collaboration });
          this.commit('magazine/UPDATE_FIELD', { key: 'exportedPdfs', value: magazine.exportedPdfs });
          this.commit('magazine/UPDATE_FIELD', { key: 'userPermissions', value: magazine.userPermissions });

          this.commit('magazine/UPDATE_MAGAZINE', { magazine });

          // reset data
          this.commit('collaboration/RESET_STATE');
          this.commit('library/RESET_STATE');
          this.commit('magazine/UPDATE_ROOT_FIELD', {
            key: 'magazineDeletedPages',
            value: []
          });

          if (pdfStatus) {
            this.commit('magazine/UPDATE_ROOT_FIELD', { key: 'pdfStatus', value: pdfStatus });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'magazine is saved' });

          return true;
        },
        err => {
          if (err.response.data && err.response.data.missingSvgs && err.response.data.missingSvgs.length > 0) {
            // specific case, ask client to regenerate all missing svg for pages
            return { missingSvgs: err.response.data.missingSvgs };
          } else if (err.response.data && err.response.data.invalidPages && err.response.data.invalidPages.length > 0) {
            // specific case, ask client to regenerate all invalid pages
            return { invalidPages: err.response.data.invalidPages };
          } else {
            this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

            // reload magazine if error (maybe store is not same as server state)
            this.dispatch('magazine/LOAD_MAGAZINE', { magazineId: id, loadType: 'current' })

            return false;
          }
        }
      );
  },

  PROCEED_UPLOAD_VIEWER_LOGO: function (context, { file }) {
    if (file) {
      let data = new FormData();
      data.append('libraryID', context.state.magazine.library); //important, set text parameters before image binary
      data.append('image', file, file.name);

      return this.$axios
        .post('/api/v1/magazine/uploadViewerLogo/' + context.state.magazine._id, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(r => r.data)
        .then(magazine => {
          this.commit('magazine/UPDATE_FIELD', { key: 'viewerSpecificLogo', value: magazine.viewerSpecificLogo });
          return true;
        })
        .catch(error => {
          this.dispatch('api/API_ERROR', error.response.data);
          return false;
        });
    } else {
      return false;
    }
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
